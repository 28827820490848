import z from 'zod';
import { metaSchema } from './response';

const FormTypeSchema = z.enum(['single_select', 'single_line_text', 'multi_select']);
const TextTypeSchema = z.enum(['text', 'number', 'date']);
const ClientTextTypeSchema = z.object({
  name: z.string(),
  id: TextTypeSchema,
});
export type FormTypes = z.infer<typeof FormTypeSchema>;
export type TextTypes = z.infer<typeof TextTypeSchema>;
export type ClientTextTypes = z.infer<typeof ClientTextTypeSchema>;

export const CustomQuestionSchema = z.object({
  created_at: z.string().optional(),
  form_type: FormTypeSchema,
  id: z.number().optional(),
  index: z.number().optional(),
  name: z.string(),
  options: z.array(z.string()),
  required: z.boolean(),
  text_type: z.union([ClientTextTypeSchema, TextTypeSchema]),
  updated_at: z.string().optional(),
});
export type CustomQuestionType = z.infer<typeof CustomQuestionSchema>;

export const EventAttendeeSchema = z.object({
  added_to_calendar: z.boolean(),
  comment: z.string().nullable(),
  created_at: z.string(),
  custom_rsvp_fields: z.record(z.string(), z.string()).optional(),
  email: z.string(),
  event_id: z.number(),
  first_name: z.string(),
  id: z.number(),
  last_name: z.string(),
  updated_at: z.string(),
});
export type EventAttendeeType = z.infer<typeof EventAttendeeSchema>;

export const EventSchema = z.object({
  id: z.number(),
  name: z.string(),
  full_day_event: z.boolean(),
  location: z.string(),
  organizer_name: z.string(),
  organizer_email: z.string().email(),
  description: z.string(),
  status: z.enum(['draft', 'live']),
  comments_enabled: z.boolean(),
  limited_attendance: z.boolean(),
  max_attendees: z.number().nullable(),
  timezone: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  start_date: z.string(),
  end_date: z.string(),
  number_registered: z.number(),
  event_custom_questions: CustomQuestionSchema.array(),
  rsvp_form_fields: z.number().optional(),
  event_attendees: z.array(EventAttendeeSchema).optional(),
});
export type EventType = z.infer<typeof EventSchema>;

export const EventsResponseSchema = z.object({
  events: EventSchema.array(),
  meta: metaSchema,
  demo_events: EventSchema.array().optional(),
});
export type EventsResponseType = z.infer<typeof EventsResponseSchema>;
